var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"72e226de247874514d2fc7f6a6347653"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/next-static";

import * as Sentry from '@sentry/nextjs';

// Note that clients can only access to environment variables injected at build time
// So it is impossible to inject an environment-specific variable into client configuration (https://github.com/getsentry/sentry-javascript/issues/9580)
Sentry.init({
	dsn: 'https://04c3f3e025473225eb0840d4cc76ae41@o6787.ingest.us.sentry.io/4507457988591618',
	enabled: false,
	// Add browser tracing integration to have web vitals sent to Sentry
	integrations: [Sentry.browserTracingIntegration()],
	tracesSampleRate: 0.1,
});
